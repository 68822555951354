var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _vm._m(0),
      _c("echarts", { attrs: { height: "300px", options: _vm.options } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "fz-20 fz-w" }, [_vm._v("近7天 新增订单趋势")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }