/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-09-03 18:35:12
 * @LastEditors: silencc
 * @LastEditTime: 2020-09-19 11:46:49
 */
/* eslint-disable no-underscore-dangle */
import { throttle } from '../../lib/modules/throttle';

export default {
  data: function data() {
    return {
      $_sidebarElm: null };

  },
  mounted: function mounted() {var _this = this;
    this.__resizeHandler = throttle(function () {
      if (_this.chart) {
        _this.chart.resize();
      }
    }, 100);
    window.addEventListener('resize', this.__resizeHandler);

    this.$_sidebarElm = document.getElementsByClassName('sidebar-container')[0];
    this.$_sidebarElm && this.$_sidebarElm.addEventListener('transitionend', this.$_sidebarResizeHandler);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.__resizeHandler);

    this.$_sidebarElm && this.$_sidebarElm.removeEventListener('transitionend', this.$_sidebarResizeHandler);
  },
  methods: {
    // use $_ for mixins properties
    // https://vuejs.org/v2/style-guide/index.html#Private-property-names-essential
    $_sidebarResizeHandler: function $_sidebarResizeHandler(e) {
      if (e.propertyName === 'width') {
        this.__resizeHandler();
      }
    } } };