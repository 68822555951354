/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-10-13 09:13:03
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-04 14:56:21
 */
export default {
  // color: [
  //   '#58A3F7',
  //   '#4BCED0 ',
  //   '#FB6260',
  //   '#8167F5'
  // ]
};